import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [
    {
        path: '', redirectTo: 'login', pathMatch: 'full'
    },

    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
    },

    {
        path: 'logistic',
        loadChildren: () => import('./logistic/logistic.module').then((m) => m.LogisticModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
