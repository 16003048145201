import {AvailabilityOptions} from './base.model';

export const URL_PART_LIVE = 'store';
export const PERM_READ = 'readonly';
export const PERM_WRITE = 'readwrite';
export const DB_VERSION = 1;
export const OFFLINE_ON = false;
export const DAYS_TO_SHOW = 7;

export const enum IDBInstances {
    USER = 'user',
    SHOP = 'shop'
}

export const enum IDBStorages {
    USERS = 'users',
    SHOPS = 'shops'
}

export const enum IDBMetadataIndexes {
    SYNC = 'sync_idx',
    TRACING_ID = 'tracing_id_idx',
    REAL_ID = 'real_id_idx'
}

export const enum JSONRPCMethods {
}

export const METHODS_TO_QUEUE = [
    '',
];

// FIXME: Delete after state service is implemented
export const enum EventSW {
    SYNC_IS_STARTED = 'SYNC_IS_STARTED',
    SYNC_IS_COMPLETED = 'SYNC_IS_COMPLETED'
}

export const enum SocketEvents {
    CONNECT = 'connect',
    RECONNECT = 'reconnect',
    DISCONNECT = 'disconnect',
    ERROR = 'error',
    CONNECT_ERROR = 'connect_error',
    RECONNECT_ERROR = 'reconnect_error',
    SUBSCRIBE = 'subscribe',
    PART_SEARCH_CREATED = 'sparepart_search_created',
    PART_SEARCH_STARTED = 'sparepart_search_started',
    PART_SEARCH_FINISHED = 'sparepart_search_finished',
    CHAT_UPDATED = 'chat_updated',
    NEW_SUPL_REQUEST = 'new_suplrequest',
    NEW_HIDDEN_SUPL_REQUEST = 'new_hidden_suplrequest',
    UPDATED_SUPL_REQUEST = 'updated_suplrequest',
    CLOSED_SUPL_REQUEST = 'closed_suplrequest',
    REOPENED_SUPL_REQUEST = 'reopened_suplrequest',
    DELETED_SUPL_REQUEST = 'deleted_suplrequest',
    SHIPPED_SUPL_REQUEST = 'suplrequest_shipped',
    NEW_SUPL_REQUEST_PROPOSAL = 'new_suplrequest_proposal',
    UPDATED_SUPL_REQUEST_PROPOSAL = 'updated_suplrequest_proposal',
    APPROVED_SUPL_REQUEST_PROPOSAL = 'approved_suplrequest_proposal',
    DISAPPROVED_SUPL_REQUEST_PROPOSAL = 'disapproved_suplrequest_proposal'
}

export const enum LocalStorageSeenDataFields {
    REQUEST = 'lastSeenRequestAt',
    CHAT = 'lastSeenChatAt'
}

export const enum SuplRequestFilterOptions {
    ALL = 'all',
    NEW = 'new',
    UNSEEN = 'unseen',
    UNSEEN_CHATS = 'unseen_chats',
    ANSWERED = 'answered',
    UNANSWERED = 'unanswered',
    SHIPPED = 'shipped',
    TO_SHIP = 'to_ship',
    CLOSED = 'closed'
}

export const keyboard_ru = [
    'й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ',
    'ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э',
    'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю'
];

export const keyboard_en = [
    'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']',
    'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\'',
    'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.'
];

export const enum VisibilityStates {
    VISIBLE_FOR_ALL = 'VisibleForAll',
    HIDDEN_FOR_SUPL_STORE = 'HiddenForSuplStore'
}

export const availabilityLabelMap = {
    [AvailabilityOptions.InStock]: 'text_availability_in_stock',
    [AvailabilityOptions.OutOfStock]: 'text_availability_out_stock',
    [AvailabilityOptions.WillBeAvailableInDays]: 'text_availability_in_stock_later',
    [AvailabilityOptions.WillBeAvailableInDays + '1']: 'text_availability_in_stock_tomorrow'
};

export const availabilityOptions = [
    {
        label: 'text_availability_in_stock',
        value: {
            availability: AvailabilityOptions.InStock
        }
    },

    {
        label: 'text_availability_in_stock_tomorrow',
        value: {
            availability: AvailabilityOptions.WillBeAvailableInDays,
            period: 1
        }
    },

    {
        label: 'text_availability_in_stock_later',
        value: {
            availability: AvailabilityOptions.WillBeAvailableInDays,
            period: 2
        }
    },

    {
        label: 'text_availability_out_stock',
        value: {
            availability: AvailabilityOptions.OutOfStock
        }
    }
];
