import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/ru';

moment().locale('ru');

export const MY_FORMATS = {
    parse: {
        dateInput: 'L',
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const PAGINATOR_LANG_KEYS = {
    firstPageLabel: 'label_paginator_first_page',
    lastPageLabel: 'label_paginator_last_page',
    nextPageLabel: 'label_paginator_next_page',
    previousPageLabel: 'label_paginator_prev_page',
    itemsPerPageLabel: 'label_paginator_items_per_page',
    getRangeLabel: 'label_paginator_current_position'
};

@Injectable()
export class LocalizedPaginator extends MatPaginatorIntl {
    ofLabel = 'of';

    constructor(private translate: TranslateService) {
        super();

        this.setTranslations();
    }

    setTranslations() {
        this.translate.get([
            'label_paginator_first_page',
            'label_paginator_last_page',
            'label_paginator_next_page',
            'label_paginator_prev_page',
            'label_paginator_items_per_page',
            'label_paginator_current_position'
        ]).subscribe(translation => {
            this.firstPageLabel = translation['label_paginator_first_page'];
            this.lastPageLabel = translation['label_paginator_last_page'];
            this.nextPageLabel = translation['label_paginator_next_page'];
            this.previousPageLabel = translation['label_paginator_prev_page'];
            this.itemsPerPageLabel = translation['label_paginator_items_per_page'];
            this.ofLabel = translation['label_paginator_current_position'];
            this.changes.next();
        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.ofLabel} ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
    };
}

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSelectModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        MatButtonToggleModule
    ],
    exports: [
        FlexLayoutModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSelectModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        MatButtonToggleModule
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MatPaginatorIntl, useClass: LocalizedPaginator}
    ],
})
export class UiModule {
}
