import {Component} from '@angular/core';

@Component({
    selector: 'app-text-modal',
    templateUrl: './text-modal.component.html',
    styleUrls: ['../modals.scss']
})
export class TextModalComponent {
    data: any;

    constructor() {
    }
}
