import {Injectable} from '@angular/core';
import * as moment from 'moment';

import {SuplRequest, SuplRequestLocalStorageData} from './base.model';
import {LocalStorageSeenDataFields} from './constants';

const KEY_PREFIX = 'idcar_shop_';

@Injectable()
export class SeenService {
    constructor() {
    }

    private getLocalStorageData(shopId: number): { [id: number]: SuplRequestLocalStorageData } {
        try {
            const localStorageDataString = localStorage.getItem(KEY_PREFIX + shopId);
            const localStorageData = JSON.parse(localStorageDataString);

            return localStorageData || {};
        } catch (e) {
            return {};
        }
    }

    private setLocalStorageData(shopId: number, localStorageData: { [id: number]: SuplRequestLocalStorageData }) {
        const localStorageDataString = JSON.stringify(localStorageData);

        localStorage.setItem(KEY_PREFIX + shopId, localStorageDataString);
    }

    getSuplRequestSeenData(shopId: number, suplRequestId: number): SuplRequestLocalStorageData {
        const localStorageData = this.getLocalStorageData(shopId);

        return localStorageData[suplRequestId];
    }

    setSuplRequestSeenData(shopId: number, suplRequestId: number, fields: LocalStorageSeenDataFields[] = []) {
        const localStorageData = this.getLocalStorageData(shopId);
        const suplRequestSeenData = localStorageData[suplRequestId] || {};

        fields.forEach((field) => {
            suplRequestSeenData[field] = moment().unix();
        });

        localStorageData[suplRequestId] = suplRequestSeenData;
        this.setLocalStorageData(shopId, localStorageData);
    }

    isSuplRequestNew(shopId: number, suplRequest: SuplRequest): boolean {
        const suplRequestSeenData = this.getSuplRequestSeenData(shopId, suplRequest.id);

        return !suplRequestSeenData && !suplRequest.acceptedAt && !suplRequest.proposalsCount;
    }

    isSuplRequestUnseen(companyId: number, suplRequest: SuplRequest): boolean {
        return false;
        const momentChatsUpdatedAt = suplRequest.chatsUpdatedAt ?
            moment(suplRequest.chatsUpdatedAt).unix() :
            null;
        const momentProposalsUpdatedAt = suplRequest.proposalsUpdatedAt ?
            moment(suplRequest.proposalsUpdatedAt).unix() :
            null;
        const suplRequestSeenData = this.getSuplRequestSeenData(companyId, suplRequest.id);
        const suplRequestSeenDataArr = Object.values(suplRequestSeenData);
        const isUnseen = (suplRequestSeenDataArr.length) ? suplRequestSeenDataArr.some((suplRequestSeenDataByShopId) => {
            const isChatUnseen = suplRequestSeenDataByShopId && suplRequestSeenDataByShopId.lastSeenChatAt ?
                momentChatsUpdatedAt > suplRequestSeenDataByShopId.lastSeenChatAt :
                !!momentChatsUpdatedAt;
            const isProposalUnseen = suplRequestSeenDataByShopId && suplRequestSeenDataByShopId.lastSeenProposalAt ?
                momentProposalsUpdatedAt > suplRequestSeenDataByShopId.lastSeenProposalAt :
                !!momentProposalsUpdatedAt;

            return isChatUnseen || isProposalUnseen;
        }) :  !!momentChatsUpdatedAt || !!momentProposalsUpdatedAt;

        return isUnseen;
    }

    // isSuplRequestHasUnseenChats(shopId: number, suplRequest: SuplRequest): boolean {
    //     const suplRequestSeenData = this.getSuplRequestSeenData(shopId, suplRequest.id);
    //
    //     return suplRequestSeenData && suplRequestSeenData.lastSeenChatAt ?
    //         suplRequest.chatUpdatedAt > suplRequestSeenData.lastSeenChatAt :
    //         !!suplRequest.chatUpdatedAt;
    // }

}
