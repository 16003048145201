import {groupBy} from 'lodash';
import {Car, Company, Order, Proposal, SuplRequest, SuplStore} from './base.model';
import {availabilityLabelMap, keyboard_en, keyboard_ru, URL_PART_LIVE} from './constants';

export interface Deferred<T> {
    promise: Promise<T>;
    resolve: (data?: any) => any;
    reject: (data?: any) => any;
}

export const getValue = (obj: any, keyPath: string): any => {
    const path = keyPath.split('.');
    let value = obj;

    path.forEach((key) => {
        if (value) {
            value = value[key];
        }
    });

    return value;
};

export const arrayToMap = (array: any[], key: string) => {
    return array.reduce((obj, item) => {
        obj[getValue(item, key)] = item;

        return obj;
    }, {});
};

export const createDeferredObj = (): Deferred<any> => {
    const defer: Deferred<any> = {
        promise: null,
        resolve: null,
        reject: null
    }

    defer.promise = new Promise((resolve, reject) => {
        defer.resolve = resolve;
        defer.reject = reject;
    });

    return defer;
};

export const getCarName = (car: Car): string => {
    let name = '';

    if (car) {
        if (car.title) {
            name = car.title;
        } else if (car.brand || car.model) {
            name = `${car.brand} ${car.model}`;

            if (car.registration) {
                name += ` (${car.registration})`;
            }
        } else if (car.registration) {
            name = car.registration;
        } else {
            name = car.vin;
        }
    }

    return name;
};

export const getVersion = (): string => {
    const hostname = document.location.hostname;
    let version = '';

    if (hostname) {
        const firstPart = hostname.split('.')[0];

        if (firstPart !== URL_PART_LIVE) {
            version = firstPart;
        }
    }

    return version;
};

export const convertInputSource = (text: string): string => {
    return text.split('').map((c) => {
        let index = keyboard_en.indexOf(c);

        if (index > -1) {
            return keyboard_ru[index];
        } else {
            index = keyboard_ru.indexOf(c);

            return (index > -1) ? keyboard_en[index] : c;
        }
    }).join('');
};

export const getSuplStoreNameById = (suplStoreId: number, suplStores: SuplStore[]): string => {
    const store = suplStores.find((s) => s.id === suplStoreId);

    return store ? store.name : '';
};

export const getProposalsText = (suplRequest: SuplRequest,
                                 proposals: Proposal[],
                                 suplStores: SuplStore[],
                                 translations: { [key: string]: string },
                                 isPriceIncluded = false): string => {
    const proposalsData = groupBy(proposals, 'suplStoreID');
    let isFirst = true;
    let text = '';

    text += `${suplRequest.text.trim()}\n`;
    text += `********************\n`;

    for (const suplStoreId in proposalsData) {
        if (!isFirst) {
            text += `---------------\n`;
        }

        text += `${getSuplStoreNameById(parseInt(suplStoreId, 10), suplStores)}\n`;
        text += `${proposalsData[suplStoreId].length
            ? proposalsData[suplStoreId].map((p) => {
                const proposalPrice = parseFloat(p.margin) > 0 && isPriceIncluded
                                    ? `${convertToIntegerString(p.price)} + ${convertToIntegerString(p.margin)}% = ${convertToIntegerString(p.priceWithMargin)}`
                                    : convertToIntegerString(p.priceWithMargin) || '';

                let proposalText = `${p.text.trim()} ${proposalPrice}`;

                proposalText += p.stock
                    ? p.stock.period && p.stock.period < 2
                        ? ` ${translations[availabilityLabelMap[p.stock.availability + p.stock.period]]}`
                        : ` ${translations[availabilityLabelMap[p.stock.availability]]}`
                    : '';

                return proposalText.trim();
            }).join('\n')
            : ''}\n`;

        isFirst = false;
    }

    return text;
};

export const getCompaniesAndCars = (companiesWithCars: { company: Company, cars: Car[] }[]): { companies: Company[], cars: Car[] } => {
    const companies = [];
    const cars = [];

    companiesWithCars.forEach((companyWithCars) => {
        companies.push(companyWithCars.company);
        cars.push(...companyWithCars.cars);
    });

    return { companies, cars };
};

export const convertToIntegerString = (numberStr: string): string => {
    let result = '';
    const num = parseInt(numberStr, 10);

    if (!isNaN(num)) {
        result = num.toString();
    }

    return result;
};

export const getManagersFromOrders = (orders: Order[]): { id: number, name: string }[] => {
    const managers = [];

    orders.forEach((order) => {
        if (order.suplRequests.length) {
            const suplRequest = order.suplRequests[0];

            if (suplRequest && !managers.find((m) => m.id === suplRequest.createdBy)) {
                managers.push({
                    id: suplRequest.createdBy,
                    name: suplRequest.createdByUsername
                });
            }
        }
    });

    return managers;
};
