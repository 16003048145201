import {Component} from '@angular/core';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['../modals.scss']
})
export class ConfirmationModalComponent {
    data: any;

    constructor() {
    }
}
