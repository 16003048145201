import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

@Injectable()
export class StateService {
    app = new BehaviorSubject<AppState>({
        isSync: false
    });
    network = new ReplaySubject<NetworkState>(1);
    ordersList = new BehaviorSubject<RequestsListState>({
        openedChats: [],
        openedProposals: []
    });

    constructor() {
    }

    clearState() {
        this.ordersList.next({
            openedChats: [],
            openedProposals: []
        });
    }
}

export interface AppState {
    isSync: boolean;
}

export interface NetworkState {
    isOnline: boolean;
}

export interface RequestsListState {
    openedChats: number[];
    openedProposals: number[];
}
