import {NgModule} from '@angular/core';

import {
    FilterPipe,
    OrderPipe,
    LeadingZeroPipe,
    DateFormatPipe,
    ToIntegerPipe,
    LinkifyHtmlPipe
} from './pipes.class';

@NgModule({
    declarations: [
        FilterPipe,
        OrderPipe,
        LeadingZeroPipe,
        DateFormatPipe,
        ToIntegerPipe,
        LinkifyHtmlPipe,
    ],
    exports: [
        FilterPipe,
        OrderPipe,
        LeadingZeroPipe,
        DateFormatPipe,
        ToIntegerPipe,
        LinkifyHtmlPipe,
    ]
})
export class PipesModule {
}
