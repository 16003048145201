import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {isNumber} from 'lodash';
import linkifyHtml from 'linkify-html';

import {convertInputSource, convertToIntegerString} from '../utils';

const getValuesByFields = (ob: any, fields: string[]): any[] => {
    let toReturn = [];

    for (let key in ob) {
        if (!ob.hasOwnProperty(key)) {
            continue;
        }
        if ((typeof ob[key]) === 'object' && ob[key] !== null) {
            const values = getValuesByFields(ob[key], fields);

            toReturn = toReturn.concat(values);
        } else if (fields.indexOf(key) > -1) {
            toReturn.push(ob[key]);
        }
    }
    return toReturn;
};

const compareFields = (field1: any, field2: any): number => {
    if (typeof field1 === 'number' && typeof field2 === 'number') {
        return field1 - field2;
    }
    else if (typeof field1 === 'string' && typeof field2 === 'string') {
        return field1.localeCompare(field2);
    }
    else if (typeof field1 === 'boolean' && typeof field2 === 'boolean') {
        return +field2 - +field1;
    }
    else {
        return 0;
    }
}

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
    public transform(values: any[], filter: any): any[] {
        if (!values || !values.length) {
            return [];
        }

        if (!filter.value && filter.value !== false) {
            return values;
        }

        let filterString = (typeof filter.value === 'string') ? filter.value.toLowerCase() : filter.value;

        let filteredValues = values.filter((value) => {
            return getValuesByFields(value, filter.fields).filter((val) => {
                if ((val || isNumber(val)) && typeof filterString === 'string') {
                    return val.toString().toLowerCase().search(filterString) > -1;
                } else {
                    return val === filterString;
                }
            }).length;
        });

        if (filteredValues.length === 0 && typeof filterString === 'string') {
            filterString = convertInputSource(filterString);

            filteredValues = values.filter((value) => {
                return getValuesByFields(value, filter.fields).filter((val) => {
                    if ((val || isNumber(val)) && typeof filterString === 'string') {
                        return val.toString().toLowerCase().search(filterString) > -1;
                    } else {
                        return val === filterString;
                    }
                }).length;
            });
        }

        return filteredValues;
    }
}

@Pipe({name: 'orderBy', pure: false})
export class OrderPipe implements PipeTransform {
    public transform(values: any[], orderBy: string): any[] {
        if (!values || !values.length) {
            return [];
        }

        if (!orderBy) {
            return values;
        }

        const fields = orderBy.split(',');

        return values.sort((obj1, obj2) => {
            let compareResult = 0;

            for (let i = 0; i < fields.length; i++) {
                const isDesc = fields[i].substr(0, 1) === '-';
                const fieldName = (isDesc) ? fields[i].substr(1) : fields[i];

                const obj1Value = getValuesByFields(obj1, [fieldName])[0];
                const obj2Value = getValuesByFields(obj2, [fieldName])[0];

                compareResult = (isDesc) ? compareFields(obj2Value, obj1Value) : compareFields(obj1Value, obj2Value);
                if (compareResult !== 0) {
                    break;
                }
            }

            return compareResult;
        });

    }
}

@Pipe({name: 'leadingZero'})
export class LeadingZeroPipe implements PipeTransform {
    public transform(value: string): string {
        const num = parseInt(value, 10);

        return (num < 10) ? '0' + num.toString() : num.toString();
    }
}

@Pipe({name: 'dateFormat'})
export class DateFormatPipe implements PipeTransform {
    public transform(value: string, dateFormat: string): string {
        if (value) {
            return moment(value).format(dateFormat);
        } else {
            return '';
        }
    }
}

@Pipe({name: 'toInteger'})
export class ToIntegerPipe implements PipeTransform {
    public transform(value: string): string {
        if (value) {
            return convertToIntegerString(value);
        } else {
            return '';
        }
    }
}

@Pipe({ name: 'linkifyHTML' })
export class LinkifyHtmlPipe implements PipeTransform {
    transform(str: string, settings: { [key: string]: string }): string {
        return str
            ? linkifyHtml(str, { target: '_blank', className: settings.className })
            : str == null
                ? ''
                : str;
    }
}
