import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export enum FieldTypes {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    CHIPS = 'chips',
    BUTTON = 'button',
    LIST = 'list',
    TEXT_W_BUTTON = 'text_w_button',
    TEXTAREA_W_BUTTON = 'textarea_w_button',
    LIST_INPUT = 'list_input',
    HIDDEN = 'hidden',
}

@Component({
    selector: 'app-edit-modal',
    templateUrl: './edit-modal.component.html',
    styleUrls: ['../modals.scss']
})
export class EditModalComponent {
    data: any;
    modalForm: UntypedFormGroup;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    tagsCtrl = new UntypedFormControl();
    filteredTags: Observable<string[]>;
    activeTags: string[] = [];
    allTags: string[] = ['работы', 'магазин', 'разборка'];
    standaloneModel: any;

    @ViewChild('tagInput') tagInput: ElementRef;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor() {
    }

    initComponent() {
        this.standaloneModel = {};

        this.modalForm = new UntypedFormGroup(this.getFormData(this.data.fields));
        if (this.modalForm.controls.tag) {
            this.filteredTags = this.tagsCtrl.valueChanges.pipe(
                startWith(null),
                map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
        }
    }

    add(event: MatChipInputEvent): void {
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            if ((value || '').trim()) {
                this.activeTags.push(value.trim());
            }

            if (input) {
                input.value = '';
            }

            this.tagsCtrl.setValue(null);
            this.updateFormValue(this.activeTags);
        }
    }

    remove(fruit: string): void {
        const index = this.activeTags.indexOf(fruit);

        if (index >= 0) {
            this.activeTags.splice(index, 1);
            this.updateFormValue(this.activeTags);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.activeTags.push(event.option.viewValue);
        this.tagInput.nativeElement.value = '';
        this.tagsCtrl.setValue(null);
        this.updateFormValue(this.activeTags);
    }

    updateFormValue(activeTags) {
        this.modalForm.controls.tag.setValue(activeTags.join(','));
    }

    addToList(fieldName: string, value: string) {
        this.modalForm.controls[fieldName].value.push(value);
    }

    removeFromList(fieldName: string, index: number) {
        this.modalForm.controls[fieldName].value.splice(index, 1);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
    }

    private getFormData(fields: any): any {
        const excludedTypes = [
            FieldTypes.BUTTON,
            FieldTypes.TEXT_W_BUTTON,
            FieldTypes.TEXTAREA_W_BUTTON,
        ];
        let formData = {};

        fields.forEach((field) => {
            if (!field.type) {
                field.type = FieldTypes.TEXT;
            }

            if (field.isRequired) {
                formData[field.name] = new UntypedFormControl(field.value, Validators.required);
            } else if (field.type === FieldTypes.CHIPS) {
                this.activeTags = field.value.slice();
                formData[field.name] = new UntypedFormControl(this.activeTags.join(','));
            } else if (!excludedTypes.includes(field.type)) {
                formData[field.name] = new UntypedFormControl(field.value);
            }
        });

        return formData;
    }


}
