import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {JsonrpcService} from '../jsonrpc.service';

@Injectable()
export class NonAuthGuard implements CanActivate {
    constructor(private router: Router, private jsonrpc: JsonrpcService) {
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        if (!this.jsonrpc.getToken() || !this.jsonrpc.getCurrentUserId()) {
            return true;
        }

        const user = await this.jsonrpc.getCurrentUser();

        if (this.jsonrpc.userNavigate(user)) {
            return false;
        }

        return true;
    }
}
