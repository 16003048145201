import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

const MESSAGE_DURATION = 2 * 1000;
const NOT_ACCESS_PERMISSIONS = ['denied', 'default'];

@Injectable()
export class NotificationService {

    constructor(private snackBar: MatSnackBar,
                private translate: TranslateService) {
    }private checkNotificationSupport(): boolean {
        return ('Notification' in window);
    }

    private checkNotificationPermission(): boolean {
        return ('permission' in Notification) && !NOT_ACCESS_PERMISSIONS.includes(Notification.permission);
    }

    private requestNotificationPermission(callback: Function) {
        try {
            Notification.requestPermission().then(() => {
                if (callback && typeof callback === 'function') {
                    callback();
                }
            });
        } catch (e) {
            Notification.requestPermission(() => {
                if (callback && typeof callback === 'function') {
                    callback();
                }
            });
        }
    }

    sendNativeNotification(title: string, message: string = '', params: any = {}) {
        return;
        if (this.checkNotificationSupport()) {
            this.requestNotificationPermission(() => {
                if (this.checkNotificationPermission()) {
                    this.translate.get([title, message], params).subscribe((translatedMessages) => {
                        const translatedTitle = translatedMessages[title];
                        const translatedMessage = translatedMessages[message];

                        const notification = new Notification(translatedTitle, {
                            body: translatedMessage,
                            requireInteraction: true
                        });
                    });
                }
            });
        }
    }

    success(message: string): void {
        this.translate.get(message).subscribe((translatedMessage) => {
            this.snackBar.open(translatedMessage, '', {
                duration: MESSAGE_DURATION,
                panelClass: 'state-success',
                verticalPosition: 'top'
            });
        });
    }

    error(message: string, data: any = {}): void {
        this.translate.get(message, data).subscribe((translatedMessage) => {
            this.snackBar.open(translatedMessage, '', {
                duration: MESSAGE_DURATION,
                panelClass: 'state-error',
                verticalPosition: 'top'
            });
        });
    }

}
