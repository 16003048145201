import {Injectable} from '@angular/core';
import {NotificationService} from './notification.service';

const ABC = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

@Injectable()
export class ErrorHandlingService {

    constructor(private notificationService: NotificationService) {
    }

    processError(errorData: any): void {
        switch (errorData.code) {
            case 1:
                this.notificationService.error('text_session_expired');
                break;

            case 102:
                break;

            case 400:
                this.notificationService.error('Bad request');
                break;

            case 409:
                this.notificationService.error('error_report_invalid_format', {
                    row: errorData.data.row + 1,
                    column: ABC[errorData.data.column],
                    msg: errorData.data.msg
                });
                break;

            case 500:
                this.notificationService.error('Internal server error');
                break;

            case 502:
                this.notificationService.error('Bad gateway');
                break;

            default:
                const message = errorData.message ? `: ${errorData.message}` : '';
                
                this.notificationService.error(`Error${message}`);
                break;
        }
    }

}
