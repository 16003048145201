import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {TextModalComponent} from './text-modal/text-modal.component';
import {EditModalComponent} from './edit-modal/edit-modal.component';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';

@Injectable()
export class ModalsService {
    constructor(private dialog: MatDialog) { }

    showConfirmationModal(title: string, note: string, width: string = '450px'): Promise<boolean> {
        const modalConfig = {
            title: title,
            note: note,
            buttons: [
                {
                    label: 'button_label_yes',
                    value: true
                },
                {
                    label: 'button_label_no',
                    value: false
                }
            ]
        };
        const modal = this.dialog.open(ConfirmationModalComponent, {
            width: width
        });

        modal.componentInstance.data = modalConfig;

        return modal.afterClosed().toPromise();
    }

    showTextModal(title: string, text: string, width: string = '720px'): Observable<string> {
        const modalConfig = {
            title: title,
            text: text
        };
        const modal = this.dialog.open(TextModalComponent, {
            width: width
        });

        modal.componentInstance.data = modalConfig;

        return modal.afterClosed();
    }

    showEditModal(data: any, width: string = '450px'): Promise<any> {
        const modal = this.dialog.open(EditModalComponent, {
            width: width
        });

        modal.componentInstance.data = data;
        modal.componentInstance.initComponent();

        return modal.afterClosed().toPromise();
    }
}
