import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {JsonrpcService} from '../jsonrpc.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private jsonrpc: JsonrpcService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (this.jsonrpc.getToken()) {
            return true;
        }

        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
