import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {IdbService} from './idb.service';
import {ErrorHandlingService} from './error-handling.service';
import {StateService} from './state.service';

import {environment} from '../../environments/environment';
import {IDBStorages, IDBInstances} from './constants';

import {User} from './base.model';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class JsonrpcService {

    private API_URL = environment.apiUrl;

    constructor(private router: Router,
                private http: HttpClient,
                private idbService: IdbService,
                private errorHandlingService: ErrorHandlingService,
                private state: StateService) {
    }

    private generateTracingId(): string {
        return String(Date.now());
    }

    private handleError(context: JRPCRequestContext) {
        return (error: any): Observable<any> => {
            const errorResponse = {
                error: {
                    code: error.status,
                    context: context
                }
            };

            return of(errorResponse as any);
        };
    }

    performRequest(url: string, method: string, params: any = {}): Observable<JRPCResponse<any>> {
        params.context = this.getContext();

        return this.http.post(
            `${this.API_URL}${url}?method=${method}`,
            {
                id: Date.now(),
                jsonrpc: '2.0',
                method: method,
                params: params
            },
            httpOptions
        ).pipe(
            map((data: JRPCResponse<any>) => {
                if (data.error) {
                    this.errorHandlingService.processError(data.error);

                    if ([1, 2, 3].includes(data.error.code)) {
                        this.logout();
                        return {};
                    }
                }

                return data;
            }),
            catchError(this.handleError(params.context))
        );
    }

    async setCurrentUser(userData: User) {
        await this.idbService.saveData(IDBInstances.USER, IDBStorages.USERS, [userData]);
        localStorage.setItem('currentUserId', String(userData.userID));
        localStorage.setItem('currentUserToken', userData.jwt);
        localStorage.setItem('selectedCompanies', JSON.stringify([]));
    }

    async getCurrentUser(): Promise<User> {
        const userId = this.getCurrentUserId();

        if (userId) {
            const users = await this.idbService.getData(IDBInstances.USER, IDBStorages.USERS, userId);

            if (users && users.length) {
                return users[0];
            }

            return null;
        } else {
            return null;
        }
    }

    getToken(): string {
        const currentUserToken = localStorage.getItem('currentUserToken');

        if (currentUserToken) {
            return currentUserToken;
        } else {
            return '';
        }
    }

    getContext() {
        return {
            tracingID: this.generateTracingId(),
            token: this.getToken()
        };
    }

    getCurrentUserId(): number {
        const currentUserId = localStorage.getItem('currentUserId');

        if (currentUserId) {
            return Number(currentUserId);
        } else {
            return null;
        }
    }

    healthCheck() {
        this.performRequest('healthcheck', 'healthcheck').subscribe((data) => {
            console.warn('HEALTH CHECK', data.result);
        });
    }

    userNavigate(user: User): boolean {
        if (user) {
            this.router.navigate(['/logistic']);
            return true;
        }

        return false;
    }

    logout() {
        this.state.clearState();
        localStorage.removeItem('currentUserId');
        localStorage.removeItem('currentUserToken');
        localStorage.removeItem('selectedCompanies');
        localStorage.removeItem('productsStartDate');
        localStorage.removeItem('productsEndDate');
        this.idbService.deleteData(IDBInstances.USER, IDBStorages.USERS);
        this.idbService.deleteData(IDBInstances.SHOP, IDBStorages.SHOPS);
        this.router.navigate(['/login']);
    }

    sendMetric(reason: string) {
        this.http.post(
            `${this.API_URL}metric/rpc?method=logSocketIssue`,
            {
                'id': Date.now(),
                'jsonrpc': '2.0',
                'method': 'logSocketIssue',
                'params': {
                    url: window.location.pathname,
                    userAgent: navigator.userAgent,
                    message: reason
                }
            },
            httpOptions
        ).subscribe();
    }
}

export interface JRPCRequestContext {
    tracingID: string;
    token: string;
}

export interface JRPCResponse<T> {
    result?: T;
    error?: any;
}
