import {BrowserModule} from '@angular/platform-browser';
import {NgModule, Injector, APP_INITIALIZER} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LOCATION_INITIALIZED} from '@angular/common';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {UiModule} from './base/ui.module';
import {PipesModule} from './base/pipes/pipes.module';


import {AppComponent} from './app.component';
import {TextModalComponent} from './base/modals/text-modal/text-modal.component';
import {EditModalComponent} from './base/modals/edit-modal/edit-modal.component';
import {ConfirmationModalComponent} from './base/modals/confirmation-modal/confirmation-modal.component';


import {AuthGuard} from './base/guards/auth.guard';
import {NonAuthGuard} from './base/guards/non-auth.guard';


import {JsonrpcService} from './base/jsonrpc.service';
import {IdbService} from './base/idb.service';
import {NotificationService} from './base/notification.service';
import {StateService} from './base/state.service';
import {ErrorHandlingService} from './base/error-handling.service';
import {ModalsService} from './base/modals/modals.service';
import {SeenService} from './base/seen.service';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

        locationInitialized.then(() => {
            const langToSet = 'ru';

            translate.setDefaultLang('ru');
            translate.use(langToSet).subscribe(() => {
                console.info(`Successfully initialized '${langToSet}' language.'`);
            }, err => {
                console.error(`Problem with '${langToSet}' language initialization.'`);
            }, () => {
                resolve(null);
            });
        });
    });
}

@NgModule({
    imports: [
        BrowserModule,
        UiModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
    ],
    declarations: [
        AppComponent,
        TextModalComponent,
        EditModalComponent,
        ConfirmationModalComponent,
    ],
    providers: [
        AuthGuard,
        NonAuthGuard,
        JsonrpcService,
        IdbService,
        NotificationService,
        StateService,
        ErrorHandlingService,
        ModalsService,
        SeenService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
