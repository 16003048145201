<div class="block-edit-modal">
    <h1 mat-dialog-title>{{ data.title | translate }}</h1>
    <mat-dialog-content class="modal-form">
        <form
            [formGroup]="modalForm"
            novalidate>
                <div *ngFor="let field of data.fields">
                    <mat-form-field *ngIf="field.type === 'text'">
                        <input
                                matInput
                                [placeholder]="field.label | translate"
                                [formControlName]="field.name">
                        <mat-error *ngIf="modalForm.controls[field.name].hasError('required')">
                            {{ field.requiredMessage | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="field.type === 'textarea'">
                        <textarea
                                matInput
                                matTextareaAutosize
                                matAutosizeMinRows="3"
                                matAutosizeMaxRows="5"
                                [placeholder]="field.label | translate"
                                [formControlName]="field.name"></textarea>
                        <mat-error *ngIf="modalForm.controls[field.name].hasError('required')">
                            {{ field.requiredMessage | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="field.type === 'chips'">
                        <mat-chip-list #chipList>
                            <mat-chip
                                    *ngFor="let tag of activeTags"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(tag)">
                                {{ tag }}
                                <mat-icon matChipRemove *ngIf="removable" data-translate="no">cancel</mat-icon>
                            </mat-chip>
                            <input
                                    #tagInput
                                    [placeholder]="field.label | translate"
                                    [formControl]="tagsCtrl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add($event)">

                            <input
                                    matInput
                                    hidden
                                    [formControlName]="field.name">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                                {{ tag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field *ngIf="field.type === 'list'">
                        <mat-select
                            [placeholder]="field.label | translate"
                            [formControlName]="field.name">
                            <mat-option *ngIf="!field.settings.withoutEmpty" value="">--</mat-option>
                            <mat-option *ngFor="let opt of field.options" [value]="opt.id">
                                {{ opt.label | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="modalForm.controls[field.name].hasError('required')">
                            {{ field.requiredMessage | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="field.type === 'text_w_button'">
                        <div fxLayout="row">
                            <input
                                    matInput
                                    [(ngModel)]="standaloneModel.textWithButtonValue"
                                    [ngModelOptions]="{ standalone: true }"
                                    [placeholder]="field.label | translate">
                            <button mat-raised-button
                                    color="primary"
                                    style="min-width: 38px;"
                                    (click)="field.settings.callback.call(field.settings.context, field.settings.argument, standaloneModel.textWithButtonValue); standaloneModel.textWithButtonValue = ''">
                                <mat-icon *ngIf="field.settings.buttonIcon" data-translate="no">{{ field.settings.buttonIcon }}</mat-icon>
                                <span *ngIf="field.settings.buttonLabel">{{ field.settings.buttonLabel | translate }}</span>
                            </button>
                        </div>
                    </mat-form-field>

                    <div *ngIf="field.type === 'list_input'">
                        <mat-form-field>
                            <div fxLayout="row">
                                <input
                                        matInput
                                        [(ngModel)]="standaloneModel[field.name]"
                                        [ngModelOptions]="{ standalone: true }"
                                        [placeholder]="field.label | translate">
                                <button mat-raised-button
                                        color="primary"
                                        style="min-width: 38px;"
                                        (click)="addToList(field.name, standaloneModel[field.name]); standaloneModel[field.name] = ''">
                                    <mat-icon *ngIf="field.settings.buttonIcon" data-translate="no">{{ field.settings.buttonIcon }}</mat-icon>
                                    <span *ngIf="field.settings.buttonLabel">{{ field.settings.buttonLabel | translate }}</span>
                                </button>
                            </div>
                        </mat-form-field>

                        <div *ngFor="let listItem of modalForm.controls[field.name].value; let i = index">
                            <div fxLayout="row" class="list-input-item">
                                <div fxFlex class="list-input-item-value">{{ listItem }}</div>
                                <div fxFlex="27px" fxLayout="row" class="list-input-item-actions">
                                    <button mat-icon-button (click)="removeFromList(field.name, i)">
                                        <mat-icon data-translate="no">delete_forever</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="field.type === 'button'">
                        <button mat-button
                                (click)="field.value.callback.call(field.value.context, field.value.argument)">
                            {{ field.label | translate }}
                        </button>
                    </div>

                    <div *ngIf="field.type === 'hidden'">
                        <input  type="hidden"
                                [formControlName]="field.name">
                    </div>
                </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-action">
        <button mat-button
                *ngFor="let button of data.buttons"
                [mat-dialog-close]="(button.isReturnData) ? modalForm.value : null">{{ button.label | translate }}</button>
    </mat-dialog-actions>
</div>
