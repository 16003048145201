import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

import {StateService} from './base/state.service';
import {NotificationService} from './base/notification.service';

import {EventSW} from './base/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'IDCar store app';
    isSync: boolean;

    constructor(private router: Router,
                private location: Location,
                private state: StateService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.state.app.subscribe((appState) => {
            this.isSync = appState.isSync;
        });

        this.initNetworkCheck();
        this.initEventsListenerFromServiceWorker();
    }

    initNetworkCheck() {
        window.addEventListener('online', () => {
            this.setNetworkState();
        });

        window.addEventListener('offline', () => {
            this.setNetworkState();
            this.notificationService.error('text_offline_mode');
        });

        this.setNetworkState();
    }

    initEventsListenerFromServiceWorker() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                const swEvent = event.data.serviceWorkerEvent;

                switch (swEvent) {
                    case EventSW.SYNC_IS_STARTED:
                        this.state.app.next({
                            isSync: true
                        });
                        break;

                    case EventSW.SYNC_IS_COMPLETED:
                        break;
                }
            });
        }
    }

    private setNetworkState() {
        this.state.network.next({
            isOnline: navigator.onLine
        });
    }
}

