import {Injectable} from '@angular/core';
import {isObject} from 'lodash';
import {IDBInstances, IDBStorages, IDBMetadataIndexes, DB_VERSION, PERM_READ, PERM_WRITE} from './constants';

const IDB_SCHEMAS = {
    [IDBInstances.USER]: [
        {
            name: IDBStorages.USERS,
            key: 'userID',
            indexes: []
        }
    ],

    // [IDBInstances.SHOP]: [
    //     {
    //         name: IDBStorages.SHOPS,
    //         key: 'id',
    //         indexes: []
    //     }
    // ]
}

@Injectable()
export class IdbService {
    private IDBMap: {
        [key: string]: Promise<IDBDatabase>
    } = {};
    constructor() { }

    private createDB(dbName: string, dbSchema: DBSchema[]): Promise<IDBDatabase> {
        return new Promise((resolve) => {
            const openRequest = indexedDB.open(dbName, DB_VERSION);

            openRequest.onupgradeneeded = () => {
                const db = openRequest.result;

                switch (db.version) {
                    case 0:
                        break;
                    default:
                        dbSchema.forEach((storeSchema) => {
                            if (!db.objectStoreNames.contains(storeSchema.name)) {
                                const store = db.createObjectStore(storeSchema.name, {
                                    keyPath: storeSchema.key
                                });

                                storeSchema.indexes.forEach((index) => {
                                    store.createIndex(`${index}_idx`, index, {unique: false, multiEntry: true});
                                });
                                store.createIndex(IDBMetadataIndexes.SYNC, 'metadata.sync');
                                store.createIndex(IDBMetadataIndexes.TRACING_ID, 'metadata.tracingId');
                                store.createIndex(IDBMetadataIndexes.REAL_ID, 'metadata.realId');
                            }
                        });
                        break;
                }
            };

            openRequest.onsuccess = () => {
                resolve(openRequest.result);
            };

            openRequest.onerror = () => {
                resolve(null);
            };
        });
    }

    private getDB(dbName: string): Promise<IDBDatabase> {
        if (this.IDBMap[dbName]) {
            return this.IDBMap[dbName];
        } else if (IDB_SCHEMAS[dbName]) {
            const dbPromise = this.createDB(dbName, IDB_SCHEMAS[dbName]);

            this.IDBMap[dbName] = dbPromise
            return this.IDBMap[dbName];
        } else {
            return Promise.resolve(null);
        }
    }

    getData(dbName: string, storageName: string, query?: any): Promise<any> {
        return new Promise(async (resolve) => {
            const db = await this.getDB(dbName);

            if (db && !db.objectStoreNames.contains(storageName)) {
                resolve(false);
                console.warn(`non-existing DB store: ${storageName}`);
            } else if (db) {
                let request;
                const transaction = db.transaction(storageName, PERM_READ);
                const store = transaction.objectStore(storageName);

                if (!query) {
                    request = store.getAll();
                } else if (isObject(query)) {
                    for (let key in query) {
                        const index = store.index(`${key}_idx`);
                        request = index.getAll(query[key]);
                    }
                } else {
                    request = store.getAll(IDBKeyRange.only(query));
                }

                request.onsuccess = () => {
                    resolve(request.result);
                };

                request.onerror = () => {
                    resolve(null);
                };

                transaction.oncomplete = () => {
                    console.warn(`got all from DB store: ${storageName}`);
                };
            } else {
                resolve(null);
            }
        });
    }

    saveData(dbName: string, storageName: string, data: any[]): Promise<boolean> {
        return new Promise(async (resolve) => {
            const db = await this.getDB(dbName);

            if (db && !db.objectStoreNames.contains(storageName)) {
                resolve(false);
                console.warn(`non-existing DB store: ${storageName}`);
            } else if (db) {
                const transaction = db.transaction(storageName, PERM_WRITE);
                const store = transaction.objectStore(storageName);

                data.forEach((d) => {
                    store.put(d);
                });

                transaction.oncomplete = function () {
                    console.warn(`added to DB store: ${storageName}`);
                    resolve(true);
                };
            } else {
                resolve(false);
            }
        });
    }

    deleteData(dbName: string, storageName: string, query?: any): Promise<boolean> {
        return new Promise(async (resolve) => {
            const db = await this.getDB(dbName);

            if (db && !db.objectStoreNames.contains(storageName)) {
                resolve(false);
                console.warn(`non-existing DB store: ${storageName}`);
            } else if (db) {
                let request;
                const transaction = db.transaction(storageName, PERM_WRITE);
                const store = transaction.objectStore(storageName);

                if (!query) {
                    request = store.clear();

                    request.onsuccess = () => {
                        resolve(true);
                        console.warn(`cleared DB store: ${storageName}`);
                    };
                } else if (isObject(query)) {
                    for (let key in query) {
                        const index = store.index(`${key}_idx`);
                        request = index.openKeyCursor(IDBKeyRange.only(query[key]));
                    }

                    request.onsuccess = () => {
                        const cursor = request.result;

                        if (cursor) {
                            store.delete(cursor.primaryKey);
                            cursor.continue();
                        }
                    };
                } else {
                    request = store.delete(query);

                    request.onsuccess = () => {
                        resolve(true);
                    };
                }

                request.onerror = () => {
                    resolve(false);
                };

                transaction.oncomplete = function () {
                    resolve(request.result);
                    console.warn(`deleted from DB store: ${storageName}`);
                };
            } else {
                resolve(false);
            }
        });
    }
}

export interface DBSchema {
    name: string;
    key: string;
    indexes: string[];
}
